<template>
    <div>
        <div class="default_box">
            <div class="default_header">
                <h1>Регистрация</h1>
            </div>
            <div class="default_box_content bg_white p15">
                <EmailRegistration class="reg_form"></EmailRegistration>
            </div>
        </div>    
    </div>
</template>
<script>
import EmailRegistration from "@/components/App/User/Form/EmailRegistration";
export default {
    name: "UserRegistration",
    components: {
        EmailRegistration,
    },
    metaInfo() {
        return {
            title: `Регистрация `,
            meta: [
                {name: 'description', content: 'Регистрация нового пользователя' },
                {property: 'og:url', content: this.$config.url + this.$route.fullPath},
                {property: 'og:title', content: `Регистрация `},
                {property: 'og:description', content: 'Регистрация нового пользователя'},
                {property: 'og:image', content:'https://okaygorod.com/images/logoHD.png'},
                {property: 'og:type', content: 'website' }
            ]
        }
    },
}
</script>
<style scoped>

</style>