<template>
    <div v-if="Preloader">
        <div v-if="registrationStatus">
            <b-alert show variant="success">
                <h4 class="alert-heading">Осталось подтвердить электронную почту</h4>
                <p>
                    Ссылку на подтверждение письмом отправили на вашу электронную почту
                </p>
                <hr>
                <p class="mb-0">
                    Активируйте аккаунт для входа на свою страницу.
                </p>
                <div v-if="error" class="error_box" v-html="error"></div>
                <button class="button button_default" @click="Login" type="button">Войти</button>
            </b-alert>
            <EmailLogin ref="login" v-show="false" />
        </div>
        <div v-else>
            <form @submit.prevent="api">
                <label for="name" class="main_input_label">Имя</label>
                <input id="name" type="text" class="main_input" v-model="form.name" minlength="2" required>
                <label for="lastName" class="main_input_label">Фамилия</label>
                <input id="lastName" type="text" class="main_input" v-model="form.lastName" minlength="2" required>
                <label for="email" class="main_input_label">E-mail</label>
                <input id="email" type="text" class="main_input" v-model="form.email" required>
                <label for="password" class="main_input_label">Пароль</label>
                <input id="password" type="password" class="main_input" v-model="form.password" minlength="7" required>
                <label for="rePassword" class="main_input_label">Повторите пароль</label>
                <input id="rePassword" type="password" class="main_input" v-model="form.rePassword" minlength="7" required>
                <label for="avatar" class="main_input_label">Аватар</label>
                <b-form-file v-model="form.file" ref="files" id="avatar" accept="image/jpeg, image/png, image/gif" class="main_input" plain></b-form-file>
                <button class="button button_default" type="submit">Регистрация</button>
            </form>
        </div>
    </div>
    <div v-else>
        <PreLoadBox />
    </div>
</template>
<script>
/* eslint-disable */
import EmailLogin from "@/components/App/User/Form/EmailLogin";

export default {
    components: {
        EmailLogin,
    },
    props: {
        dropdown: Object
    },
    name: "EmailRegistration",
    data() {
        return {
            error: null,
            Preloader: 'finish',
            form: {
                email: '',
                name: '',
                lastName: '',
                password: '',
                rePassword: '',
                file: null
            },
            registrationStatus: false
        }
    },
    methods: {
        api: function() {
            this.Preloader = null;
            let url = this.$config.api_url + 'User.emailRegistration';
            let form = new FormData();
            form.set('name', this.form.name);
            form.set('f_name', this.form.lastName);
            form.set('email', this.form.email);
            form.set('pass', this.form.password);
            form.set('pic_250', this.form.file);
            this.$http.post(url, form).then((response) => {
                console.log(response.status);
                if (response.status === 200) {
                    this.registrationStatus = true;
                    this.$notify({
                        group: 'error',
                        type: 'success',
                        text: 'Регистрация прошла успешно',
                        title: 'Поздравляем!',
                        duration: 5000,
                    });
                }
            }).finally(() => {
                this.Preloader = 'finish';
            })
        },
        Login() {
            this.api_login(this.form.email, this.form.password);
        },
        api_login(email, pass) {
            let url = this.$config.api_url + 'User.emailLogin';
            this.$http.post(url, { email, pass }).then((response) => {
                this.$user = response.data.response.item;
                this.$notify({
                    group: 'error',
                    type: 'success',
                    text: 'Мы рады, что Вы снова зашли к нам',
                    title: 'С возвращением!',
                    duration: 5000,
                });
            this.$router.push({ name: 'Home' });
            }).catch((error) => {
                this.error = error.response.data.response.message;
                this.$user = null;
            })
        },
    },
}
</script>
<style scoped>
.button_default {
    margin-top: 20px;
}

.error_box {
    margin-top: 20px;
    background: #b71c1c;
    color: #fff;
    border-radius: 12px;
    padding: 15px;
}
</style>